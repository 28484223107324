html {
  scroll-behavior: smooth;
}

body.newrefpage {
  font-family: "Inter", sans-serif;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body.newrefpage a:hover {
  text-decoration: none;
}

body.newrefpage h1,
body.newrefpage h2,
body.newrefpage h3,
body.newrefpage h4,
body.newrefpage h5,
body.newrefpage h6 {
  font-family: "Inter", sans-serif;
}

body.newrefpage header {
  padding-top: 40px;
}

body.newrefpage .btnsmall {
  width: 100%;
  max-width: 150px;
  line-height: 35px;
  background: #2ab1ff;
  font-size: 10.5px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 50px;
  transition: 0.5s;
}

body.newrefpage .btnsmall:hover {
  color: #fff;
  box-shadow: inset 210px 0 0 0 #1e82ce;
}

body.newrefpage .blackbtn {
  background: #2d353c;
  color: #ffffff;
  transition: 0.5s;
}

body.newrefpage .blackbtn:hover {
  color: #fff000;
}

body.newrefpage a.blackbtn:hover {
  box-shadow: inset 210px 0 0 0 #fff000;
  color: #111;
}

body.newrefpage section {
  padding: 80px 0;
}

body.newrefpage .topsection h1 {
  font-size: 35px;
  color: #2ab1ff;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 0px;
  position: relative;
  padding-top: 10px;
  text-transform: uppercase;
}

/* .topsection h1:before{
	position:absolute;
	top:0px;
	left:0px;
	width:60px;
	height:5px;
	background:#2ab1ff;
	content:'';
} */

body.newrefpage .topsection span {
  font-weight: 600;
  color: #646464;
}

body.newrefpage .topsection p {
  color: #5a5a5a;
  font-weight: 300;
  font-size: 28px;
  margin: 10px 0 0 0;
}

body.newrefpage .topsection img {
  /* margin-left:100px; */
  filter: drop-shadow(44.433px 11.906px 20px rgba(0, 0, 0, 0.24));
}

body.newrefpage .btnsec {
  display: flex;
  margin-top: 40px;
}

body.newrefpage .btnlg {
  width: 100%;
  max-width: 172px;
  line-height: 47px;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 13.76px;
  font-weight: 800;
  letter-spacing: 1px;
  transition: 0.5s;
}

body.newrefpage .btnoutline {
  color: #6dc0ff;
  border: 2px solid #6dc0ff;
  transition: 0.5s;
}

body.newrefpage .btnoutline:hover {
  color: #fff;
  box-shadow: inset 210px 0 0 0 #6dc0ff;
}

body.newrefpage a.btnoutline.btnlg {
  line-height: 43px;
}

body.newrefpage .stats {
  background: #2ab1ff;
  padding-bottom: 60px;
}

body.newrefpage .statsin {
  text-align: center;
  color: #fff;
}

body.newrefpage .statsin label {
  margin: 0px;
  font-size: 17.99px;
  width: 100%;
}

body.newrefpage .statsin span {
  margin: 0px;
  font-size: 36.4px;
  font-weight: 800;
}

body.newrefpage h2 {
  color: #2ab1ff;
  font-size: 37.4px;
  font-weight: 800;
  position: relative;
  padding-top: 20px;
  letter-spacing: 2px;
}

body.newrefpage h2:after {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60px;
  height: 5px;
  background: #2ab1ff;
  content: "";
}

body.newrefpage section.about p {
  color: #006dac;
  font-size: 15.88px;
  line-height: 31.75px;
  letter-spacing: 1px;
}

body.newrefpage .contract {
  background: #2ab1ff;
}

body.newrefpage .contract .col {
  position: relative;
}

body.newrefpage .contract h2 {
  color: #fff;
  padding: 0 0 20px 0;
  margin: 0px;
}

body.newrefpage .contract h2:after {
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  top: inherit;
  bottom: 0px;
}

body.newrefpage .contract a {
  color: #2ab1ff;
  font-size: 14px;
  padding: 12px 20px;
  display: inline-block;
  border: 2px solid #ffffff;
  border-radius: 50px;
  margin-top: 40px;
  letter-spacing: 1px;
  background: #fff;
  font-weight: 600;
}

body.newrefpage .collectibles h2 {
  text-transform: uppercase;
  padding: 0 0 20px 0;
}

body.newrefpage .collectibles h2:after {
  left: 50%;
  transform: translateX(-50%);
  top: inherit;
  bottom: 0px;
}

body.newrefpage .collectibles h2 span {
  width: 100%;
  color: #0063b5;
  display: inline-block;
}

body.newrefpage .collectibles h3 {
  color: #2ab1ff;
  font-size: 17.99px;
  font-weight: 800;
  margin-top: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

body.newrefpage .collectibles p {
  font-size: 17.99px;
  color: #2ab1ff;
  letter-spacing: 1px;
}

body.newrefpage .tagcoin {
  width: 100%;
  max-width: 634px;
  border-radius: 53px;
  filter: drop-shadow(1.932px 0.518px 15px rgba(16, 12, 36, 0.2));
  background-color: #ffffff;
  display: inline-block;
  margin: 60px auto -70px auto;
  padding: 40px;
  position: relative;
}

body.newrefpage .tagcoin h4 {
  font-size: 32px;
  letter-spacing: 3px;
  line-height: 37px;
  color: #2ab1ff;
  font-weight: 800;
  font-style: italic;
  font-family: "Inter";
  text-align: center;
}

body.newrefpage .tagcoin p {
  font-style: italic;
  color: #0063b5;
  line-height: 37px;
  margin: 0px;
}

.howitworks h2 {
  text-transform: uppercase;
  padding: 0 0 20px 0;
}

.howitworks h2:after {
  position: absolute;
  top: inherit;
  left: 0px;
  width: 60px;
  height: 5px;
  content: "";
  background: #fff;
  bottom: 0px;
}

.stepsrow h4 {
  font-size: 23.26px;
  font-weight: 800;
  color: #fff;
  margin: 10px 0 5px 0;
}

.stepsrow p {
  color: #fff;
  font-size: 11.16px;
  letter-spacing: 1px;
}

.stepsrow .col-md-3 {
  text-align: center;
}

.firststep {
  width: 100%;
  max-width: 203px;
}

.secondstep {
  width: 100%;
  max-width: 240px;
}

.thirdstep {
  width: 100%;
  max-width: 240px;
}

.laststep {
  width: 100%;
  max-width: 203px;
}

body.newrefpage .steps {
  position: relative;
  width: 100%;
  max-width: 203px;
  display: inline-block;
}

body.newrefpage .steps label {
  width: 41px;
  height: 41px;
  border-radius: 21px;
  background-color: #2ab1ff;
  font-size: 15px;
  letter-spacing: 2px;
  color: #ffffff;
  font-weight: 800;
  text-align: center;
  line-height: 41px;
  margin: 0px;
  position: absolute;
  left: 18px;
}

.cirlcespinner {
  width: 100%;
  max-width: 383px;
  position: relative;
}

.cirlcespinner img.hashimg {
  position: absolute;
  left: 72px;
  top: 68px;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-7px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-7px);
  }
}

.spinning-loader {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgba(29, 161, 242, 0.2);
  border-left-color: rgb(29, 161, 242);
  background: transparent;
  animation: rotate-s-loader 10s linear infinite;
  position: absolute;
  left: 0;
  top: 0;
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

body.newrefpage .steps:before {
  content: "";
  position: absolute;
  left: -119px;
  width: 120px;
  height: 2px;
  background: #fff;
  top: 50%;
}

body.newrefpage .steps:after {
  content: "\f105";
  position: absolute;
  left: -32%;
  font-size: 20px;
  color: #fff;
  top: 43%;
  font-family: "FontAwesome";
}

.thirdstep .steps:before {
  left: -135px;
  width: 138px;
}

.firststep .steps:before,
.firststep .steps:after {
  display: none;
}

.community h2 {
  text-transform: uppercase;
  padding: 0 0 20px 0;
}

.community h2:after {
  left: 50%;
  transform: translateX(-50%);
  top: inherit;
  bottom: 0px;
}

.comcont {
  text-align: center;
}

.comcont img {
  max-height: 70px;
  width: auto;
}

.comcont h4 {
  font-size: 21px;
  letter-spacing: 2px;
  color: #2ab1ff;
  margin: 15px 0 10px 0;
  font-weight: 800;
}

.comcont p {
  font-size: 15.8px;
  color: #646464;
  margin: 0px;
  letter-spacing: 1px;
}

section.parties {
  background: #f7f7f7;
}

.parties .col-md-6 {
  padding: 100px 0;
}

.parties .col-md-6:first-child h2 {
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  padding: 0 0 20px 0;
}

.parties .col-md-6:first-child h2:after {
  position: absolute;
  top: inherit;
  left: 0px;
  width: 60px;
  height: 5px;
  content: "";
  background: #fff;
  bottom: 0px;
}

.partiescont {
  width: 100%;
  max-width: 550px;
  margin-right: auto;
  padding-left: 90px;
}

.partiescont h2 {
  padding: 0 0 20px 0;
}

.partiescont h2:after {
  position: absolute;
  top: inherit;
  right: 0px;
  width: 60px;
  height: 5px;
  content: "";
  bottom: 0px;
  left: inherit;
}

.partiescont p {
  font-size: 16px;
  letter-spacing: 2px;
  color: #2ab1ff;
  font-weight: 300;
}

.recregister {
  background: #2ab1ff;
}

.recregister h2 {
  padding: 0 0 20px 0;
}

.recregister h2:after {
  position: absolute;
  top: inherit;
  left: 0px;
  width: 60px;
  height: 5px;
  content: "";
  background: #fff;
  bottom: 0px;
}

.recregister ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.recregister ul li {
  width: 100%;
  max-width: 223px;
  height: 75px;
  border-radius: 32px;
  background-color: #ffffff;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 24px;
  font-size: 21px;
  letter-spacing: 2px;
  color: #157bc9;
  font-weight: 800;
}

.recregister ul li label {
  font-size: 11px;
  font-weight: 400;
  margin: 0px;
}

.recregister ul li:nth-child(2),
.recregister ul li:nth-child(5) {
  margin-left: 25px;
  margin-right: 25px;
}

.recregister .bluebtn {
  background: #1e82ce;
  transition: 0.5s;
}

.recregister .bluebtn:hover {
  box-shadow: inset 210px 0 0 0 #fff000;
  color: #111;
}

.trending img {
  margin-bottom: -10px;
}

.trending .swiper-container {
  width: 100%;
  padding-right: 40px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.swiper-slide ul li {
  width: 158px;
  line-height: 63px;
  border-radius: 32px;
  filter: drop-shadow(4.83px 1.294px 7.5px rgba(16, 12, 36, 0.1));
  background-color: #ffffff;
  font-size: 19px;
  letter-spacing: 2px;
  color: #2ab1ff;
  font-weight: 800;
  margin: 0 0 30px 0;
}

.swiper-slide ul li:last-child {
  margin-bottom: 20px;
}

.trending .swiper-wrapper {
  padding-top: 10px;
  width: auto !important;
  height: auto !important;
}

.trending .swiper-button-next,
.trending .swiper-button-prev {
  right: 0px !important;
  left: inherit;
}

.trending h2 {
  text-transform: uppercase;
  padding: 0 0 20px 0;
  margin: 0 0 60px 0;
}

.trending h2:after {
  position: absolute;
  top: inherit;
  left: 0px;
  width: 60px;
  height: 5px;
  content: "";
  bottom: 0px;
}

body.newrefpage footer .btn {
  width: 100%;
  max-width: 274px;
  line-height: 74px;
  border-radius: 21px;
  background-color: #2ab1ff;
  font-size: 22px;
  letter-spacing: 2px;
  color: #ffffff;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0px;
  transition: 0.5s;
  border: none;
}

body.newrefpage footer .btn:hover {
  box-shadow: inset 290px 0 0 0 #fff000;
  color: #111;
}

body.newrefpage footer p {
  font-size: 21px;
  letter-spacing: 2px;
  color: #ffffff;
  font-weight: 400;
  margin: 20px 0;
}

body.newrefpage small {
  font-size: 9px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 400;
  line-height: 12px;
  display: inherit;
  margin-bottom: 5px;
}

body.newrefpage .copyright {
  font-size: 16px;
  letter-spacing: 2px;
  color: #ffffff;
  font-weight: 400;
}

.socialmedia ul {
  display: flex;
  justify-content: flex-end;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.socialmedia ul li {
  margin-left: 30px;
  min-width: 35px;
  text-align: center;
  height: 35px;
}

.socialmedia ul li:hover {
  color: #2ab1ff;
  box-shadow: 0 0 34px 0 #ffffff;
  border-radius: 100%;
}

.socialmedia ul li a {
  font-size: 25px;
  color: #fff;
}

/* .socialmedia ul li:hover a{
	color:#000;
} */

body.newrefpage button:focus {
  outline: none;
}

.macslider {
  position: relative;
  width: 100%;
  max-width: 605px;
  margin-left: 70px;
  text-align: center;
}

.slide {
  position: absolute;
  width: 100%;
  max-width: 95%;
  height: 240px;
  top: 21px;
  /*left: 36%;
     background-color: #fff; */
  left: 50%;
  transform: translateX(-50%);
}

.slide > div {
  width: 100%;
  height: 100%;
  background-size: contain;
  position: absolute;
  animation: slide 30s infinite;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center;
}

.slide > div:nth-child(2) {
  animation-delay: 5s;
}

.slide > div:nth-child(3) {
  animation-delay: 10s;
}

.slide > div:nth-child(4) {
  animation-delay: 15s;
}
.slide > div:nth-child(5) {
  animation-delay: 20s;
}
.slide > div:nth-child(6) {
  animation-delay: 25s;
}

@keyframes slide {
  10% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  40% {
    transform: scale(1);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1259px) {
  .topsection img {
    width: 100%;
    margin: 0px;
  }
  .macslider {
    margin: 0px;
  }
  .slide {
    height: 82%;
    top: 19px;
  }
  .slide > div {
    background-position: center;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  body.newrefpage header {
    padding-top: 20px;
  }
  .topsection {
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .topsection h1 {
    font-size: 30px;
  }
  .topsection p {
    font-size: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tcalert {
    width: 60% !important;
  }
  .topsection {
    padding-bottom: 40px !important;
  }
  .topsection img {
    margin-left: 0;
    width: auto;
    max-width: 100%;
    margin-top: 50px;
  }
  .statsin label {
    font-size: 14px;
  }
  .statsin span {
    font-size: 34px;
  }
  .cirlcespinner img {
    width: auto;
    max-width: 100%;
  }
  .contract .col:before {
    display: none;
  }
  .contract .col:after {
    display: none;
  }
  .trending {
    padding-bottom: 0px;
  }
  .trending img {
    margin-left: -140px;
  }
  .stepsrow .col-md-3 {
    max-width: 22%;
  }

  .steps img {
    width: 100%;
  }
  .steps:before {
    display: none;
  }
  .steps:after {
    display: none;
  }
  .parties .col-md-6 {
    padding: 60px 15px;
  }
  .partiescont {
    padding-left: 0px;
  }
  .recregister ul li {
    max-width: 133px;
    height: 65px;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .recregister ul li:nth-child(2),
  .recregister ul li:nth-child(5) {
    margin-left: 15px;
    margin-right: 15px;
  }
  .topsection h1 {
    font-size: 33px;
    letter-spacing: 1px;
  }
  .cirlcespinner img.hashimg {
    left: 17%;
    top: 17%;
  }
  .topsection p {
    font-size: 24px;
  }
  .macslider {
    margin-left: 0px;
  }
  .slide {
    height: 65%;
    top: 26%;
  }
  .slide > div {
    background-position: center;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .slide {
    height: 68%;
    top: 22%;
  }
}

@media screen and (max-width: 991px) {
  .hideonmobile {
    display: none !important;
  }
  body.newrefpage header {
    padding: 20px 0;
    background: #2ab1ff;
  }
  .topsection {
    margin-top: 0px;
    padding-top: 30px;
  }

  body.newrefpage a.logo img {
    display: none;
  }
  header .col-md-8 {
    width: 100%;
    display: inline-block !important;
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .tcalert {
    width: 75% !important;
  }
  .container {
    max-width: 96%;
  }
  body.newrefpage section {
    padding: 60px 0;
  }
  body.newrefpage h2 br {
    display: none;
  }
  .topsection {
    text-align: center;
  }
  .topsection img {
    margin-left: 0px;
    width: auto;
    max-width: 100%;
  }
  .topsection h1:before {
    display: none;
  }
  .topsection h1 {
    font-size: 33px;
    color: #1e82ce;
    letter-spacing: 1px;
  }
  .topsection p span {
    width: 100%;
    display: inline-block;
    font-size: 50px;
    letter-spacing: 5px;
    color: #2ab1ff;
    font-weight: 800;
    line-height: 60px;
    margin-bottom: 20px;
  }
  .topsection .col-md-6.pt-5 {
    padding-top: 0px !important;
  }
  .topsection .btnsec {
    justify-content: center;
    margin-bottom: 70px;
  }
  .statsin {
    margin-bottom: 20px;
  }
  .stats .col-md-3:last-child .statsin {
    margin-bottom: 0px;
  }
  .about .row {
    flex-direction: column-reverse;
  }
  .about .row .col-md-5 {
    text-align: left !important;
    margin-bottom: 40px;
  }
  .about .row .col-md-5 img {
    width: auto;
    max-width: 100%;
  }
  .contract .col:before {
    display: none;
  }
  .contract .col:after {
    display: none;
  }
  .contract a {
    word-break: break-all;
    font-size: 12px;
  }
  .trending .row {
    flex-direction: column-reverse;
  }
  .trending img {
    width: auto;
    max-width: 100%;
    margin-top: 50px;
  }
  .swiper-slide ul li {
    width: auto;
    padding: 0 20px;
    border-radius: 0px;
  }
  .trending .justify-content-end {
    justify-content: flex-start !important;
    padding-right: 0px !important;
  }
  .collectibles h2 span {
    width: auto;
  }
  .tagcoin {
    border-radius: 5px;
  }
  .howitworks h2 {
    text-align: center;
  }
  .howitworks h2:after {
    left: 50%;
    transform: translateX(-50%);
  }
  .stepsrow .col-md-3 {
    max-width: 50%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
  .steps:before {
    display: none;
  }
  .steps:after {
    display: none;
  }
  .community h2 br {
    display: none;
  }
  .comcont {
    margin-bottom: 30px;
  }
  .community .row.mt-5.justify-content-center {
    margin-top: 0px !important;
  }
  .parties .col-md-6:first-child {
    text-align: right;
    padding: 80px 15px;
  }
  .parties .col-md-6 {
    padding: 60px 15px;
  }
  .parties .col-md-6:first-child h2:after {
    left: inherit;
    right: 0px;
  }
  .partiescont {
    padding-left: 0px;
    text-align: left !important;
    max-width: 100%;
  }
  .partiescont h2:after {
    left: 0px;
  }
  .partiescont .btnsec {
    justify-content: flex-start !important;
  }
  .recregister h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  .recregister h2:after {
    left: 50%;
    transform: translateX(-50%);
  }
  .recregister ul li:nth-child(2),
  .recregister ul li:nth-child(5) {
    margin-left: 3%;
    margin-right: 3%;
  }
  .recregister ul li {
    font-size: 15px;
    max-width: 30%;
    height: 55px;
  }
  .recregister ul li label {
    font-size: 10px;
  }
  .recregister .justify-content-end {
    justify-content: center !important;
  }
  body.newrefpage footer {
    padding: 50px 0 0 0;
    text-align: center;
  }
  .socialmedia ul {
    justify-content: center;
    margin-top: 20px;
  }
  .socialmedia ul li {
    margin: 0 20px;
  }
  body.newrefpage footer .btn {
    border-radius: 0px;
  }
  .cirlcespinner img.hashimg {
    left: 17%;
    top: 17%;
  }
  .macslider {
    max-width: 100%;
    margin: 0px;
  }
  .slide {
    height: 81%;
    top: 7%;
    max-width: 100%;
  }
  .slide > div {
    background-position: center;
  }
}

@media screen and (min-width: 580px) and (max-width: 767px) {
  section.about .row {
    flex-direction: initial;
    flex-wrap: inherit;
  }
  section.about .row .col-md-5 {
    width: 50%;
    margin-bottom: 0px;
  }
  .trending {
    padding-bottom: 60px;
  }
  .trending .row {
    flex-direction: row-reverse;
    flex-wrap: initial;
    justify-content: flex-end;
    position: relative;
  }
  .trending .row .col-md-7 {
    width: 65%;
  }
  .trending .row .col-md-5 {
    position: absolute;
    width: 52%;
    right: -20px;
    bottom: -50px;
  }
  .cirlcespinner img.hashimg {
    width: 20px !important;
  }
}

@media screen and (max-width: 479px) {
  .tcalert {
    width: 90% !important;
  }
  body.newrefpage h2 {
    font-size: 34px;
  }
  .stepsrow .col-md-3 {
    max-width: 100%;
  }
  .stepsrow .col-md-3:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 425px) {
  .topsection h1 {
    font-size: 30px;
  }
  .topsection p span {
    font-size: 35px;
    line-height: 45px;
  }
  .topsection p {
    font-size: 23px;
  }
  .recregister ul li {
    max-width: 45%;
  }
  .recregister ul li:nth-child(2),
  .recregister ul li:nth-child(5) {
    margin-left: 0;
    margin-right: 0;
  }
  .recregister ul li:nth-child(even) {
    float: right;
  }
}
@media screen and (max-width: 375px) {
  .swiper-slide ul,
  .swiper-slide ul li {
    width: 100%;
  }
  .btnlg {
    font-size: 11px;
  }
}
ul#welcomeRR {
  list-style: none;
}
ul#welcomeRR li {
  display: inline-block;
  min-width: 250px;
  font-size: 2rem;
  color: #1093ef;
}

.myhashtags ul li span {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.myhashtags ul li {
  background: #61a0d1;
  color: #fff;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
}
.myhashtags ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.myhashtags ul li label {
  margin: 0px;
}
span.per_utc_hr {
  font-size: 11px;
  display: block;
  text-align: center;
}
span.halving_counter {
  width: 100%;
  display: block;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 10px;
  font-size: 12px;
}
span.icon_box {
  display: block;
  width: 20px;
  max-width: 30px;
  padding: 10px;
  text-align: center;
  background: #1f82ce;
  height: 30px;
}
span.icon_box i {
  margin: auto;
}
a.btnn.btnTransfer {
  padding: 5px 10px;
  margin: auto;
  color: #fff;
  margin-left: 34%;
  font-weight: 400;
}
input.transferText {
  width: 100%;
  border-radius: 0;
  border: solid 1px #61a0d1;
  padding: 5px;
}
a.btnn.btnTransfer:hover {
  color: #fff;
  background: black;
}
.trcontent .body {
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 660px;
}

.trcontent .form-container .field-container:first-of-type {
  grid-area: name;
}

.trcontent .form-container .field-container:nth-of-type(2) {
  grid-area: number;
}

.trcontent .form-container .field-container:nth-of-type(3) {
  grid-area: expiration;
}

.trcontent .form-container .field-container:nth-of-type(4) {
  grid-area: security;
}

.trcontent .field-container input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.trcontent .field-container {
  position: relative;
}

.trcontent .downarroww {
  justify-content: center;
  display: flex;
  grid-area: number;
  margin-top: 2%;
  cursor: pointer;
}

.trcontent .swapbutton {
  background: #028fff;
  cursor: pointer;
  color: white;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  border: 0px;
}

.trcontent .swapbutton:disabled {
  background: #5991bc;
  cursor: not-allowed;
  color: white;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  border: 0px;
}

.trcontent .liqbutton {
  background: #028fff;
  cursor: pointer;
  color: white;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  border: 0px;
}

.trcontent .liqbutton:disabled {
  background: #5991bc;
  cursor: not-allowed;
  color: white;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  border: 0px;
}

.trcontent .form-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: "name name" "number number" "expiration expiration" "security security";
  width: 100%;
  max-width: 500px;
  padding: 5px;
  color: #707070;
  margin: 0 auto;
}

.trcontent .form-container.swap {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: "name name" "number number" "expiration expiration" "security security";
  width: 100%;
  max-width: 500px;
  padding: 5px;
  color: #707070;
  margin: 0 auto;
  margin-top: 28px;
}

.trcontent .labl {
  padding-bottom: 5px;
  font-size: 13px;
}

.trcontent .inp {
  margin-top: 3px;
  padding: 5px;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}

.trcontent .containerr {
  width: 100%;
  max-width: 500px;
  max-height: 55px;
  height: 12vw;
  padding: 5px;
  margin: 0 auto;
}

.trload .loading-text {
  display: inline-flex;
  position: relative;
  top: 5px;
  margin-left: 13px;
}

.trload .loading {
  padding: 5px;
}

.trloadsm .loading-text {
  display: inline-flex;
  position: relative;
  margin-left: 10px;
  margin-bottom: 0px;
}

.trloadsm .loading {
  padding: 0px;
}

.disnone .loading-text {
  display: none !important;
}
.loading {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  /* left, top and position just for the demo! */
  margin: auto;
}
@keyframes rotate {
  /* 100% keyframe for  clockwise. 
	   use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  /* 100% keyframe for  clockwise. 
	   use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.tokbutton {
  display: inline-block !important;
  background-color: #1775bc;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  text-transform: uppercase;
  margin: 0px;
  padding: 4px 18px;
}

.slippageslide {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 15px;
  -webkit-appearance: none;
  background: #d7d7d7;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: none;
}
.slippageslide::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #028fff;
  cursor: pointer;
  border: 4px solid #ffe0de;
  box-shadow: -407px 0 0 400px #028fff;
}
.slippageslide::-moz-range-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #028fff;
  cursor: pointer;
  border: 4px solid #ffe0de;
  box-shadow: -407px 0 0 400px#028fff;
}

.tokenliqbody {
  margin-top: 20px !important;
  min-height: 690px !important;
}

.bnbliqbody {
  margin-top: 20px !important;
  min-height: 640px !important;
}
.tmoonfarmbody,
.tokenfarmbody {
  margin-top: 20px !important;
}
.tmoonfarmbody .infodetz {
  margin-bottom: 1px;
}
.tokenfarmbody .infodetz {
  margin-bottom: 10px;
}
ul#tabs-list {
  text-align: center;
  padding: 0px;
  margin-top: 5px;
}
ul#tabs-list li {
  display: inline-block;
  text-align: center;
  font-size: 0.875em;
  width: 50%;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
  border-right: 1px solid #dfdfdf;
  position: relative;
  cursor: pointer;
}
ul#tabs-list li label.panel-label {
  position: relative;
  padding: 24px 0;
}

ul#tabs-list li.inactive {
  border-bottom: 1px solid #dfdfdf;
  background: #efefef;
}

.flxrow {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.flxcolumn {
  flex: 33.33%;
}

@media (max-width: 450px) {
  .swap {
    margin-top: 40px !important;
  }
}

@media (max-width: 700px) {
  .hidem {
    display: none;
  }
}

@media (min-width: 700px) {
  .splitbr {
    display: none;
  }
}

@media (max-width: 700px) {
  .splitbropp {
    display: none;
  }
}

@media (max-width: 500px) {
  .navbar {
    padding: 0rem 1rem !important;
  }
  .btn-outline-sm {
    font-size: 3vw !important;
    padding: 0.65rem 0.5rem 0.65rem 0.5rem !important;
  }
}

@media (max-width: 550px) {
  .trcontent .body {
    min-height: 705px;
  }
}
@media (min-width: 350px) and (max-width: 1000px) {
  button {
    font-size: 15px !important;
  }
  .trcontent .downarroww {
    margin-top: 4% !important;
  }
  .sectobox {
    margin-top: 4% !important;
  }
  .tokenliqbody {
    min-height: 610px !important;
  }
  .bnbliqbody {
    min-height: 590px !important;
  }
  .tmoonfarmbody {
    min-height: 545px !important;
  }
  .tokenfarmbody {
    min-height: 545px !important;
  }
  .infodetz {
    margin-top: 4% !important;
  }
}

@media (min-width: 400px) and (max-width: 1000px) {
  .trcontent .body {
    min-height: 630px;
  }
}
@media (min-width: 350px) and (max-width: 400px) {
  .trcontent .body {
    min-height: 615px;
  }
}
@media (max-width: 350px) {
  .trcontent .body {
    min-height: 790px;
  }
}

@media (max-width: 1020px) {
  .navbar {
    flex-wrap: nowrap !important;
  }
  .flxrow {
    display: block;
  }
}

@media (max-height: 1700px) {
  .copyright {
    position: static !important;
  }
}

.copyright {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.trbody {
  font-size: 12px;
}

.selltr {
  color: red;
  background-color: rgb(255 0 0 / 5%);
}

.buytr {
  color: #28a745;
  background-color: rgb(68 255 21 / 5%);
}
.liqheight .tdbdy {
  max-height: 650px;
}
.farmheight .tdbdy {
  min-height: 580px;
  max-height: 605px;
}
.tfarmheight .tdbdy {
  min-height: 815px;
  max-height: 815px;
}
.tradeheight .tdbdy {
  max-height: 650px;
}
.allbox {
  margin-top: 4%;
}
.pricebox {
  background-color: rgb(222 240 255);
  width: 49%;
  padding: 5px 0px;
  margin: auto;
  margin-bottom: 18px;
  display: inline-block;
}
.rightbox {
  float: right;
}
.heading {
  display: inline-block;
}
.headingin {
  font-size: 13px;
  margin-top: 3px;
  float: right;
}
@media (max-width: 1020px) {
  .pricebox {
    display: block;
    width: 72%;
    min-width: 235px;
  }
  .rightbox {
    float: none;
  }
}

@media (max-width: 650px) {
  .allbox {
    margin-top: 6%;
    margin-bottom: 2%;
  }
  .pricebox {
    margin-bottom: 16px;
  }
}

@media (max-width: 600px) {
  .heading {
    display: block;
    margin: auto;
    margin-bottom: 10px;
  }
  .headingin {
    margin-bottom: 15px;
    float: none;
    text-align: center;
  }
}

.greenlight {
  color: #30e630;
}

.redlight {
  color: red;
}

.table thead th {
  font-size: 13px !important;
  vertical-align: middle !important;
}

.checkbox-ios {
  width: 45px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.checkbox-ios__toggle {
  display: none;
}
.checkbox-ios__toggle + .checkbox-ios__label {
  display: block;
  position: relative;
  transition: 0.3s;
  border-radius: 50px;
  background-color: #2a6db3;
  height: 22px;
  margin-bottom: 0;
  cursor: pointer;
}
.checkbox-ios__toggle + .checkbox-ios__label:before {
  transition: 0.3s;
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  width: 12px;
  height: 12px;
  top: 5px;
  left: 5px;
}
.checkbox-ios__value {
  display: block;
  float: left;
  width: 50%;
  font-size: 9px;
  text-align: center;
  margin-top: 27px;
  line-height: 1.5;
  font-family: sans-serif;
}
.checkbox-ios__value.left {
  text-align: left;
  font-weight: bold;
  color: blue;
}
.checkbox-ios__value.right {
  text-align: right;
}
.checkbox-ios__toggle:checked + .checkbox-ios__label {
  background-color: #2cc524;
}
.checkbox-ios__toggle:checked + .checkbox-ios__label:before {
  left: calc(100% - 17px);
}
.checkbox-ios__toggle:checked + .checkbox-ios__label .right {
  font-weight: bold;
  color: blue;
}
.checkbox-ios__toggle:checked + .checkbox-ios__label .left {
  font-weight: normal;
  color: black;
}
.checkbox-ios--blue .checkbox-ios__toggle:checked + .checkbox-ios__label {
  background-color: #2a6db3;
}

.checkbox-market {
  width: 45px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.checkbox-market__toggle {
  display: none;
}
.checkbox-market__toggle + .checkbox-market__label {
  display: block;
  position: relative;
  transition: 0.3s;
  border-radius: 50px;
  background-color: #2a6db3;
  height: 22px;
  margin-bottom: 0;
  cursor: pointer;
}
.checkbox-market__toggle + .checkbox-market__label:before {
  transition: 0.3s;
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  width: 12px;
  height: 12px;
  top: 5px;
  left: 5px;
}
.checkbox-market__value {
  display: block;
  float: left;
  width: 50%;
  font-size: 9px;
  text-align: center;
  margin-top: 27px;
  line-height: 1.5;
  font-family: sans-serif;
}
.checkbox-market__value.left {
  text-align: left;
  font-weight: bold;
  color: blue;
}
.checkbox-market__value.right {
  text-align: right;
}
.checkbox-market__toggle:checked + .checkbox-market__label {
  background-color: #2cc524;
}
.checkbox-market__toggle:checked + .checkbox-market__label:before {
  left: calc(100% - 17px);
}
.checkbox-market__toggle:checked + .checkbox-market__label .right {
  font-weight: bold;
  color: blue;
}
.checkbox-market__toggle:checked + .checkbox-market__label .left {
  font-weight: normal;
  color: black;
}
.checkbox-market--blue
  .checkbox-market__toggle:checked
  + .checkbox-market__label {
  background-color: #2a6db3;
}

.checkbox-mine {
  width: 45px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.checkbox-mine__toggle {
  display: none;
}
.checkbox-mine__toggle + .checkbox-mine__label {
  display: block;
  position: relative;
  transition: 0.3s;
  border-radius: 50px;
  background-color: #2a6db3;
  height: 22px;
  margin-bottom: 0;
  cursor: pointer;
}
.checkbox-mine__toggle + .checkbox-mine__label:before {
  transition: 0.3s;
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  width: 12px;
  height: 12px;
  top: 5px;
  left: 5px;
}
.checkbox-mine__value {
  display: block;
  float: left;
  width: 50%;
  font-size: 9px;
  text-align: center;
  margin-top: 27px;
  line-height: 1.5;
  font-family: sans-serif;
}
.checkbox-mine__value.left {
  text-align: left;
  font-weight: bold;
  color: blue;
}
.checkbox-mine__value.right {
  text-align: right;
}
.checkbox-mine__toggle:checked + .checkbox-mine__label {
  background-color: #2cc524;
}
.checkbox-mine__toggle:checked + .checkbox-mine__label:before {
  left: calc(100% - 17px);
}
.checkbox-mine__toggle:checked + .checkbox-mine__label .right {
  font-weight: bold;
  color: blue;
}
.checkbox-mine__toggle:checked + .checkbox-mine__label .left {
  font-weight: normal;
  color: black;
}
.checkbox-mine--blue .checkbox-mine__toggle:checked + .checkbox-mine__label {
  background-color: #2a6db3;
}

.lpfmax {
  background: transparent;
  border: 0px;
  height: 16px;
  margin-right: 15px;
  margin-top: 7px;
  font-weight: 700;
  color: #1e82ce;
  cursor: pointer;
  outline: 0px;
}

.dropdown {
  width: 88px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgb(0 0 0 / 5%);
  background-color: rgb(232 245 255);
}
.dropdownlpt {
  width: 126px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgb(0 0 0 / 5%);
  background-color: rgb(232 245 255);
}

@media (max-width: 450px) {
  .dropdownlpt {
    width: 110px;
  }
  .dropdown-item {
    font-size: 12px !important;
  }
  .dropdown-body {
    padding: 3px;
  }
  .lpfmax {
    margin-right: 5px;
    background: rgb(222, 240, 255);
    height: 22px;
  }
}

.dropdown-header {
  padding: 10px 5px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 8px 2px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
  margin-left: -1px;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.dropdown .icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
  transform: rotate(90deg);
}

.dropdown .icon.open {
  transform: rotate(90deg);
}

.bnbicon {
  background-image: url("../img/bnbicon.png");
  display: inline-block;
  height: 18px;
  width: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}
.busdicon {
  background-image: url("../img/busdicon.png");
  display: inline-block;
  height: 18px;
  width: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}
.tagicon {
  background-image: url("../img/favicon.png");
  display: inline-block;
  height: 18px;
  width: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.egoldicon {
  background-image: url("../img/egoldicon.png");
  display: inline-block;
  height: 18px;
  width: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.tmoonicon {
  background-image: url("../img/tmoonicon.png");
  display: inline-block;
  height: 18px;
  width: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.stagicon {
  background-image: url("../img/stagicon.png");
  display: inline-block;
  height: 18px;
  width: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.bnbiconlpt {
  background-image: url("../img/bnbicon.png");
  display: inline-block;
  height: 18px;
  width: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  left: -6px;
  top: 3px;
}
.busdiconlpt {
  background-image: url("../img/busdicon.png");
  display: inline-block;
  height: 18px;
  width: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  left: -6px;
  top: 3px;
}
.egoldiconlpt {
  background-image: url("../img/egoldicon.png");
  display: inline-block;
  height: 18px;
  width: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  left: -1px;
  top: -1px;
}

.tagiconlpt {
  background-image: url("../img/favicon.png");
  display: inline-block;
  height: 18px;
  width: 14px;
  background-size: contain;
  background-repeat: no-repeat;
}

.extradets {
  background-color: rgb(171 217 255) !important;
}

.extradets label {
  color: #3a3a3a !important;
}

.extradets span {
  color: #006dbf !important;
}
h2#csoonFarm {
  font-size: 16px;
  margin: auto;
  display: inline-block;
  width: auto;
  top: 30vh;
  position: relative;
  background: #048fff;
  padding: 10px;
  left: 30%;
  color: #fafafa;
  min-width: 200px;
  text-align: center;
  border-radius: 125px;
}
.body.tdbdy.withOverlay {
  background-image: linear-gradient(
    180deg,
    #f0f0f0 25%,
    #e8e8e8 25%,
    #e8e8e8 50%,
    #f0f0f0 50%,
    #f0f0f0 75%,
    #e8e8e8 75%,
    #e8e8e8 100%
  );
  background-size: 80px 80px;
}

.tcalert {
  width: 100%;
  margin: 20px auto 0px auto;
}

.statbody {
  height: 240px !important;
  min-height: 240px !important;
  max-height: 240px !important;
}

@media screen and (max-width: 350px) {
  .statbody {
    height: 280px !important;
    min-height: 280px !important;
    max-height: 280px !important;
  }
}
