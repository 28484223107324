.app-body {
  font-family: "Arial";
}

a:hover {
  text-decoration: none;
}

.bluebg {
  background: #1e82ce;
}

.header {
  padding: 20px 0;
}

.app-content {
  padding: 30px;
  background: #fff;
  max-width: 90%;
  margin: auto;
  margin-top: 25px;
}

@media (min-width: 995px) and (max-width: 1185px) {
  .tradeheight {
    height: 885px;
  }
}
@media (min-width: 994px) {
  .farmheight {
    height: 750px;
  }
}
@media (min-width: 994px) {
  .tfarmheight {
    height: 958px;
  }
}
@media (min-width: 1020px) {
  .tradeheight {
    height: 815px;
  }
  .liqheight {
    height: 810px;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .app-content {
    padding: 30px 20px;
  }
  .accinfo-list li {
    font-size: 13px !important;
  }
}
@media (min-width: 350px) and (max-width: 400px) {
  .app-content {
    padding: 30px 15px;
  }
  .accinfo-list li {
    font-size: 12px !important;
  }
}
@media (min-width: 500px) and (max-width: 750px) {
  .app-content {
    max-width: 85%;
  }
}
@media (min-width: 750px) and (max-width: 994px) {
  .app-content {
    max-width: 70%;
  }
}

@media (min-width: 1200px) {
  .app-content {
    max-width: 540px;
  }
}
@media (min-width: 1700px) {
  .app-content {
    max-width: 580px;
  }
}

.app-content h3 {
  font-size: 18px;
  color: #3a3a3a;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 20px 0;
}

.wallet-lists {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.wallet-lists li {
  width: 100%;
  margin: 0 0 12px 0;
  padding: 0px;
  border: 1px solid #1e82ce;
  border-radius: 5px;
}

.wallet-lists li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  color: #0063b5;
  font-weight: 600;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.app-footer {
  background-color: #c6e0f3;
  padding: 20px 25px;
  margin: 25px -30px 0px;
}

.app-footer h4 {
  font-size: 16px;
  line-height: 24px;
  color: #191919;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.app-footer p {
  font-size: 16px;
  line-height: 24px;
  color: #434343;
  margin: 0px;
}

.app-btnsec {
  background: #1e82ce;
  padding: 14px 0;
  text-align: center;
  margin: 0 -30px -30px;
}

.app-btnsec .btn {
  width: 100%;
  max-width: 125px;
  line-height: 28px;
  text-align: center;
  background: #252525;
  text-transform: uppercase;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 0px;
  padding: 0px;
}

.dl-lists {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.dl-lists li {
  margin: 0 0 12px 0;
  background-color: #0c4d7e;
}

.dl-lists li a {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 48px;
  display: block;
}

.balancesec {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: #595959;
}

.balancesec span {
  padding: 0 10px;
}

.accinfo-list {
  margin: 0 auto;
  padding: 5px;
  list-style: none;
  max-width: 500px;
}

.accinfo-list li {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 5px 0;
  background-color: rgb(222 240 255);
}

.accinfo-list li span {
  text-align: right;
}

.accinfo-list li:nth-child(even) {
  background-color: rgb(222 240 255);
}

.accinfo-list li label {
  margin: 0px;
  color: #343333;
}

.accinfo-list li span {
  color: #218ad9;
}

.app-content ul.nav li {
  width: 100%;
  margin: 0 0 6px 0;
}

.app-content .nav-tabs {
  border: none;
}

.app-content ul.nav li a {
  display: block;
  line-height: 25px;
  background: rgb(222 240 255);
  font-size: 14px;
  color: #028fff;
  font-weight: bold;
  text-align: center;
  height: 100%;
}

.app-content ul.nav li a.active {
  color: #ffffff;
  background: #028fff;
}

.app-content .tab-content {
  background-color: #c7d6e1;
  margin: 20px -30px 0;
  padding: 30px;
}

.app-content .tab-content h4 {
  font-size: 16px;
  color: #242222;
  font-weight: bold;
  text-align: center;
  margin-bottom: 14px;
}

.datasec {
  width: 48.8%;
  text-align: center;
}

.datasec h5 {
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
  background: #1e82ce;
  margin: 0px;
  line-height: 19px;
  text-transform: uppercase;
}

.datasec span {
  margin: 0px;
  line-height: 25px;
  background: #62a2d2;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
  display: block;
  text-transform: uppercase;
}

a.btnn {
  background-color: #173145;
  font-size: 13px;
  color: #ffffff;
  font-weight: bold;
  line-height: 25px;
  width: 100%;
  max-width: 135px;
  text-transform: uppercase;
  text-align: center;
}

.reghashtag .searchsec {
  border: 1px solid rgba(30, 130, 206, 0.63);
  border-radius: 2px;
}

.reghashtag input[type="text"] {
  width: 100%;
  background: #e3eff8;
  font-size: 14px;
  border: none;
  border-radius: 2px 0px 0px 2px;
}

.reghashtag input[type="submit"] {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  background: #1f82ce;
  max-width: 100px;
  border: none;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
}

.hashtagsearch {
  font-size: 13px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  background: #61a0d1;
  line-height: 28px;
  min-width: 58%;
  text-align: center;
}

span.hashtagstatus {
  min-width: 40%;
  background: #1e851c;
  line-height: 28px;
  text-align: center;
  font-size: 13px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 2%;
}

.reghashtag .regbtn {
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  background-color: #505650;
  width: 100%;
  max-width: 127px;
  line-height: 28px;
  margin: 15px 0;
  display: inline-block;
}

.popscore {
  margin: 30px 0;
}

.popscore span {
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 28px;
  text-align: center;
  background: #61a0d1;
  min-width: 75%;
}

.popscore label {
  background: #1d4e74;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  margin: 0px;
  min-width: 25%;
}

.popscore h3 {
  font-size: 12px;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
}

.popscore ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.popscore ul.spelling-list li {
  background: #dedede;
  display: flex;
  padding: 2px 3px;
  border-radius: 2px;
  color: #004b9d;
  width: auto;
  font-size: 12px;
  align-items: center;
  margin: 0 2px 5px;
  font-weight: 600;
}

.popscore ul.spelling-list li span {
  min-width: inherit;
  background: #5c31c9;
  line-height: inherit;
  padding: 2px;
  margin-left: 5px;
  border-radius: 2px;
  font-size: 10px;
}

.popscore ul.hashlist li {
  background: #dedede;
  padding: 2px 3px;
  border-radius: 2px;
  color: #004b9d;
  width: auto;
  font-size: 13px;
  margin: 0 3px 5px;
  font-weight: 600;
}

.popscore h4 {
  font-size: 14px;
  color: #000000;
}

.myhashtags ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.myhashtags ul li {
  background: #61a0d1;
  color: #fff;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
}

.myhashtags ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 5px 0;
}

.myhashtags ul li:nth-child(even) {
  background: #4592ce;
}

.myhashtags ul li span {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.myhashtags ul li label {
  margin: 0px;
}

.regcontractadd {
  background: #e8e8e8;
  padding: 20px 25px;
  text-align: center;
  margin: 20px -30px 0 -30px;
}

.regcontractadd h4 {
  font-size: 11px;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0px;
}

.regcontractadd p {
  font-size: 11px;
  color: #2d2d2d;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.regcontractadd a {
  font-size: 11px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  background: #1d81cd;
  padding: 6px 8px;
}

.transauc a {
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding: 6px 18px;
}

.darkbluebg {
  background: #1d415d;
}

.darkgreenbg {
  background: #156f1f;
}

.performancedata h4 {
  font-size: 16px;
  color: #242222;
  font-weight: bold;
  text-transform: uppercase;
  margin: 15px 0;
}

.performancedata .accinfo-list li span {
  color: #343333;
}

.comingsoon {
  text-align: center;
  min-height: 300px;
  font-size: 24px;
  color: #1e82ce;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.tagtrade form {
  display: flex;
  justify-content: center;
}

.tagtrade input[type="radio"] {
  display: none;
}

.tagtrade input[type="radio"] + label {
  display: inline-block;
  background-color: #1775bc;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: uppercase;
  margin: 0px;
  padding: 4px 18px;
}

.tagtrade input[type="radio"]:checked + label {
  background-color: #19496d;
}

.lptamt {
  padding: 5px 10px;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
  background-color: #616161;
  text-transform: uppercase;
  min-width: 50%;
}

.lptvalue {
  padding: 5px 10px;
  text-align: center;
  font-size: 15px;
  color: #000000;
  font-weight: bold;
  background-color: #ccdae7;
  min-width: 50%;
}

.tradebtns a {
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  background-color: #115d95;
  width: 48.5%;
  padding: 5px 0px;
  text-transform: uppercase;
}

.tradebtns a.withdrawbtn {
  background-color: #1876bc;
}

.lptamt.tagearned {
  min-width: 60%;
}

.lptvalue.tagearnedvalue {
  min-width: 40%;
  background-color: #242628;
  color: #fff;
}

.claimyield a {
  background-color: #19496d;
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
  display: inline-block;
  text-transform: uppercase;
  padding: 5px 20px;
}

.historybtns a {
  background-color: #3b586e;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 49%;
  padding: 5px 0;
}

.tagtrade-content .tab-content {
  margin: 0px;
  background: transparent;
  padding: 0px;
}

.tagtrade-content ul.nav.nav-tabs {
  flex-wrap: nowrap;
  margin-top: 10px;
}

.tagtrade-content ul.nav.nav-tabs li {
  margin: 0px;
}

.tagtrade-content ul.nav.nav-tabs li:nth-child(2) {
  margin: 0 5px;
}

.tagtrade-content ul.nav li a {
  line-height: 29px;
}

.swapsec {
  display: flex;
  margin-bottom: 10px;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
}

.swapsec label {
  margin: 0px;
  background-color: #3b586e;
  text-align: center;
  min-width: 76px;
  line-height: 33px;
  text-transform: uppercase;
}

.swapsec select {
  min-width: 105px;
  background: #5d89ab;
  border: none;
  color: #fff;
  font-weight: bold;
}

.swapsec select:focus-visible {
  outline: none;
}

.swapsec span.value {
  background: #c9d1d8;
  color: #000000;
  width: 100%;
  text-align: center;
  line-height: 33px;
}

.swapsec span.coin {
  min-width: 105px;
  background: #5d89ab;
  border: none;
  color: #fff;
  font-weight: bold;
  line-height: 33px;
  text-align: center;
}

.tagbnb {
  font-size: 14px;
  text-transform: uppercase;
  color: #4e4c4c;
  font-weight: 600;
  text-align: center;
}

.tagbnb label {
  margin: 0px;
}

.tagbnb span {
  color: #1e82ce;
}

.swapdetvalues ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.swapdetvalues {
  margin: 20px 0;
}

.swapdetvalues li {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #4d4d4d;
}

.swapdetvalues li span {
  color: #1e82ce;
}

.liquiditybtns {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.liquiditybtns a {
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #19476a;
  min-width: 49%;
  text-align: center;
  padding: 5px 10px;
}

.liquiditybtns a:last-child {
  background: #b6b6b6;
}

p.note {
  font-size: 11px;
  line-height: 16px;
  color: #b38045;
  font-weight: bold;
  background: #e2e2e2;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
}

.invitationcode {
  text-align: center;
}

.invitationcode h4 {
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 2px 0;
}

.invitationcode label {
  font-size: 14px;
  color: #218ad9;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0px;
}

.invitationcode a {
  font-size: 14px;
  color: #218ad9;
}

.buttonsec {
  display: flex;
  justify-content: space-between;
  margin: 0 -30px;
  padding: 10px 30px;
  border-top: 2px solid #b6d8ff;
}

.buttonsec a {
  background: #116cb2;
  font-size: 11.5px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 6px 7px;
}

.sharetext {
  margin: 0 -30px;
  padding: 10px 30px;
  background: #e8e8e8;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.earnlevels .col {
  padding: 0 2px;
}

.earnlevels .col .levels {
  border: 1px solid #116cb2;
  text-align: center;
  font-size: 12px;
  color: #116cb2;
  font-weight: bold;
}

.earnlevels .col .levels label {
  margin: 0px;
  line-height: 28px;
}

.earnlevels .col .levels label.percent {
  width: 100%;
  padding: 5px 0;
  border-top: 1px solid #116cb2;
  border-bottom: 1px solid #116cb2;
  color: #218ad9;
  font-size: 13px;
  line-height: 20px;
}

.earnlevels .col .levels span {
  font-size: 11px;
  width: 100%;
  display: inline-block;
  background: #e8e8e8;
  line-height: 18px;
  padding: 5px 0;
}

.showmsg {
  text-align: center;
  background: rgb(0 0 0);
  color: #ffffff;
  font-size: 15px;
  position: absolute;
  top: -100px;
  left: 50%;
  width: 100%;
  max-width: 360px;
  transform: translateX(-50%);
  padding: 10px;
  transition: 0.5s;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showmsg.active {
  top: 15px;
}

.showmsg .fa {
  font-size: 20px;
  color: #91cb88;
  margin-right: 10px;
  position: relative;
}

.showmsg .fa:after {
  width: 12px;
  height: 12px;
  background: #fff;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}
